import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { TChemicalState } from '../../types/Chemical';
import { useTranslation } from 'react-i18next';
import FirstAidMeasurements from './panels/FirstAidMeasurements';
import Hazards from './panels/Hazards';
import Company from './panels/Company';
import { simpleDateFormat } from '../../Helpers';
import HandlingAndStorage from './panels/HandlingAndStorage';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ProtectiveMeasurements from './panels/ProtectiveMeasurements';
import { fetchWithAuthentication } from '../../actions/BaseAction';
import Exposure from './panels/Exposure';
import FireFighting from './panels/FireFighting';
import Spills from './panels/Spills';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Emergency from './panels/Emergency';

interface IChemicalProductProps {
  chemical: TChemicalState;
  expandedFirstAid: boolean;
  expandedHazards: boolean;
  expandedProducer: boolean;
  expandedHandling: boolean;
  expandedExposure: boolean;
  expandedFireFighting: boolean;
  expandedSpills: boolean;
  expandedEmergency: boolean;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const useStyles = makeStyles({
  imageContainer: {
    width: '100%',
    height: '300px',
    padding: '0 0 10px',
    textAlign: 'center',
  },
  image: {
    width: '100% !important',
    height: '100% !important',
    objectFit: 'contain',
  },
});

const ChemicalProduct: React.FC<IChemicalProductProps> = ({
  chemical,
  expandedHazards,
  expandedProducer,
  expandedFirstAid,
  expandedExposure,
  expandedFireFighting,
  expandedSpills,
  expandedEmergency,
  handleChange,
  expandedHandling,
}): JSX.Element => {
  const { t } = useTranslation();
  const [safetyDataSheet, setsafetyDataSheet] = useState('*');
  const [imageSrc, setImageSrc] = useState('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (chemical.activeChemical?.data.safetyDataSheet) {
      fetchWithAuthentication(chemical.activeChemical?.data.safetyDataSheet)
        .then(blob => {
          if (blob !== null) {
            setsafetyDataSheet(URL.createObjectURL(blob));
          }
        })
        .catch(error => {throw error});
    }

    if (chemical?.activeChemical?.data.image) {
      fetchWithAuthentication(chemical?.activeChemical?.data.image as string)
        .then(blob => {
          if (blob !== null) {
            setImageSrc(URL.createObjectURL(blob));
          }
        })
        .catch(error => {throw error});
    }
  }, [chemical.activeChemical]);

  const getRevisedDate = (date: Date) => {
    return simpleDateFormat(date);
  };

  return (
    <>
      {chemical && (
        <>
          {chemical.activeChemical?.data.image ? (
            <div style={{ width: isXs ? '100%' : '35%' }}>
              <div className={classes.imageContainer}>
                <img className={classes.image} src={imageSrc} alt={chemical?.activeChemical?.data.name as string} />
              </div>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <i
                className='fas fa-flask'
                style={{ fontSize: '150px', color: 'lightgray' }}
              />
            </div>
          )}

          {chemical.activeChemical && (
            <ProtectiveMeasurements
              exposureControls={chemical.activeChemical.data.protectiveEquipment as string}
              recommendedUse={chemical.activeChemical.data.recommendedUse as string}
              ppe={chemical.activeChemical.ppe}
              hazards={chemical.activeChemical.hazards}
              signalWordType={chemical?.activeChemical?.data.signalWordType}
            />
          )}

          {
            (chemical?.activeChemical?.data.vendor ||
            chemical?.activeChemical?.data.emergencyInformation) && (
            <Emergency
              emergencyInformation={chemical?.activeChemical?.data.emergencyInformation}
              vendor={chemical.activeChemical.data.vendor}
              expanded={expandedEmergency}
              handleChange={handleChange}
            />
          )}

          {(
            chemical?.activeChemical?.data.hazardStatements ||
            chemical?.activeChemical?.data.precautionaryStatements ||
            chemical?.activeChemical?.data.otherHazards
          ) && (
              <Hazards
                hazardClassification={chemical?.activeChemical.data.hazardClassification}
                hazardStatements={chemical?.activeChemical?.data.hazardStatements}
                precautionaryStatements={chemical?.activeChemical?.data.precautionaryStatements}
                otherHazards={chemical?.activeChemical?.data.otherHazards}
                expanded={expandedHazards}
                handleChange={handleChange}
              />
            )}

          {(
            chemical.activeChemical?.data.firstAidMeasures ||
            chemical.activeChemical?.data.symptomsOrEffects ||
            chemical.activeChemical?.data.recommendations
          ) && (
            <FirstAidMeasurements
              expanded={expandedFirstAid}
              firstAidMeasures={chemical.activeChemical?.data.firstAidMeasures}
              symptomsOrEffects={chemical.activeChemical?.data.symptomsOrEffects}
              recommendations={chemical.activeChemical?.data.recommendations}
              handleChange={handleChange}
            />
          )}

          {(
            chemical.activeChemical?.data.extinguishingMedia ||
            chemical.activeChemical?.data.specialFireHazards ||
            chemical.activeChemical?.data.adviceForFirefighters
          ) && (
            <FireFighting
              expanded={expandedFireFighting}
              extinguishingMedia={chemical.activeChemical?.data.extinguishingMedia}
              specialFireHazards={chemical.activeChemical?.data.specialFireHazards}
              adviceForFirefighters={chemical.activeChemical?.data.adviceForFirefighters}
              handleChange={handleChange}
            />
          )}

          {(
            chemical.activeChemical?.data.personalPrecautions ||
            chemical.activeChemical?.data.environmentalPrecautions ||
            chemical.activeChemical?.data.containmentAndCleanUp
          ) && (
            <Spills
              expanded={expandedSpills}
              personalPrecautions={chemical.activeChemical?.data.personalPrecautions}
              environmentalPrecautions={chemical.activeChemical?.data.environmentalPrecautions}
              containmentAndCleanUp={chemical.activeChemical?.data.containmentAndCleanUp}
              handleChange={handleChange}
            />
          )}

          {(
            chemical.activeChemical?.data.handlingAndStorage ||
            chemical.activeChemical?.data.storage ||
            chemical.activeChemical?.data.disposalConsiderations ||
            chemical.activeChemical?.data.specificEndUses
          ) && (
            <HandlingAndStorage
              expanded={expandedHandling}
              handlingAndStorage={chemical.activeChemical?.data.handlingAndStorage}
              storage={chemical.activeChemical?.data.storage}
              specificEndUse={chemical.activeChemical?.data.specificEndUses}
              disposalConsiderations={chemical.activeChemical?.data.disposalConsiderations}
              handleChange={handleChange}
            />
          )}

          {(chemical.activeChemical?.data.controlParameters && false) && (
            <Exposure
              expanded={expandedExposure}
              controlParameters={chemical.activeChemical?.data.controlParameters}
              handleChange={handleChange}
            />
          )}

          {(chemical?.activeChemical?.data.productIdentifier ||
            chemical?.activeChemical?.data.revisedDate ||
            chemical?.activeChemical?.data.locations
          ) && (
            <Company
              title={t('chemicals.additional_information')}
              productIdentifier={chemical.activeChemical.data.productIdentifier}
              locations={chemical.activeChemical.data.locations}
              revisedDate={getRevisedDate(
                chemical.activeChemical.data.revisedDate
              ) as string}
              expanded={expandedProducer}
              handleChange={handleChange}
              panelId={4}
            />
          )}

          {chemical.activeChemical?.data.safetyDataSheet && (
            <Grid container justifyContent="space-around">
              <Grid item xs={12}>
                <Button
                  type='button'
                  fullWidth
                  variant='contained'
                  color='primary'
                  target={'_blank'}
                  style={{
                    marginTop: '15px',
                    borderRadius: '15px',
                  }}
                  href={safetyDataSheet}
                  download
                >
                  {t('chemical.download.sds')}
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default ChemicalProduct;
