import * as React from 'react';
import { ChangeEvent } from 'react';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import {
  TApiChemicalLocation,
} from '../../../types/Chemical';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface ICompanyProps {
  title: string;
  productIdentifier?: string;
  locations: Array<TApiChemicalLocation>;
  revisedDate: string;
  expanded: boolean;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
  panelId: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '80%',
      flexShrink: 0,
    },
  })
);


const Company: React.FC<ICompanyProps> = ({
  title,
  productIdentifier,
  locations,
  revisedDate,
  expanded,
  handleChange,
  panelId,
}): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange(`panel${panelId}`)}
      key={`panel${panelId}`}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panelId}bh-content`}
        id={`panel${panelId}bh-header`}
      >
        <Typography className={classes.heading}>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.list}>
        {productIdentifier && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.product_identifier')}</b> <p>${productIdentifier}</p>` }} />
        )}
        {revisedDate && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.revised.date')}</b> <p>${revisedDate}</p>` }} />
        )}
        {(locations && locations.length > 0) && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.filter.location')}</b> <p>${
              locations.map((location) => {
                return location.title;
              }).join(', ')}</p>` }} />
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Company;
