import * as React from "react";
import {
  SurveyType,
  TApiSurveyItem,
  TAvailableSurveyLanguages,
} from "../../types/Survey";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { RouteComponentProps, withRouter } from "react-router";
import { reverse } from "named-urls";
import { routes } from "../../Routes";
import {
  startSurvey,
  TStartSurveyRequestData,
} from "../../actions/SurveyActions";
import { TDispatch } from "../../types/Thunk";
import { useCurrentActiveSurvey } from "../../hooks/useCurrentActiveSurvey";
import { useDispatch, useSelector } from "react-redux";
import { TAuthState } from "../../types/Auth";
import { IAppState } from "../../store/Store";
import LanguageSelector from "./LanguageSelector";
import SurveyTranslation from "./SurveyTranslation";
import { isSurveyExpired, isSurveyNotPublished } from "../../helpers/Survey";
import AlertBlock from "../AlertBlock";
import { SurveyInspectionType } from "../../types/Content";
import { useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions, useTheme, useMediaQuery,
} from '@material-ui/core';

interface ISurveyStartProps extends RouteComponentProps<any> {
  currentSurvey: TApiSurveyItem;
  language: string;
  changeSurveyLanguage: (lang: string) => void;
  resumeSurvey: () => void;
  isResumingAvailable: boolean;
  accessToken: string | null;
  preview: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(24),
      textAlign: "left",
      fontWeight: "bold",
    },
  })
);

const SurveyStart: React.FC<ISurveyStartProps> = ({
  currentSurvey,
  changeSurveyLanguage,
  language,
  history,
  isResumingAvailable,
  resumeSurvey,
  accessToken,
  preview,
  match: {
    params: { id },
  },
}): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch<TDispatch>();
  const {
    setRespondent,
    addToRespondentsStorage,
    isInspection,
    isSurveyCompleted,
  } = useCurrentActiveSurvey();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const auth: TAuthState = useSelector<IAppState, TAuthState>(
    (state: IAppState) => state.authState
  );

  const [resumeWindowShown, setResumeWindowShown] =
    useState(isResumingAvailable);

  const navigateToIdentificationPage = () => {
    if (currentSurvey.survey.surveyType === SurveyInspectionType.INSPECTION) {
      return history.push({
        pathname: reverse(routes.inspectionIdentification, { id }),
        state: { language },
      });
    }

    if (currentSurvey.survey.anonymity === SurveyType.ANONYMOUS) {
      const anonymousSurveyData: TStartSurveyRequestData = {
        surveyId: currentSurvey.survey.id,
        languageCode: language,
        access: accessToken,
        preview: preview,
      };

      return dispatch(startSurvey(anonymousSurveyData)).then((res: any) => {
        setRespondent(res.data.respondent, currentSurvey.survey.id, language);

        if (currentSurvey.survey.allowSavingProgress) {
          addToRespondentsStorage(currentSurvey.survey.id, res.data.respondent);
        }

        history.push(
          reverse(routes.surveyCategory, {
            id: currentSurvey.survey.id,
          })
        );
      });
    }
    if (currentSurvey.survey.anonymity === SurveyType.REQUIRES_LOGIN) {
      let anonymousSurveyData: TStartSurveyRequestData = {
        surveyId: currentSurvey.survey.id,
        languageCode: language,
        respondentName: auth.data?.user?.name,
        access: accessToken,
        preview: preview,
      };

      if (auth.data?.user?.department) {
        anonymousSurveyData = {
          ...anonymousSurveyData,
          departmentId: auth.data?.user?.department.id,
        };
      }
      if (auth.data?.user?.location) {
        anonymousSurveyData = {
          ...anonymousSurveyData,
          locationId: auth.data?.user?.location.id,
        };
      }

      return dispatch(startSurvey(anonymousSurveyData)).then((res: any) => {
        setRespondent(res.data.respondent, currentSurvey.survey.id, language);
        if (currentSurvey.survey.allowSavingProgress) {
          addToRespondentsStorage(currentSurvey.survey.id, res.data.respondent);
        }
        history.push(
          reverse(routes.surveyCategory, {
            id: currentSurvey.survey.id,
          })
        );
      });
    }
    return history.push({
      pathname: reverse(routes.surveyIdentification, { id }),
      state: { language },
    });
  };

  const msgPrefix = isInspection ? "inspection" : "survey";

  const _isSurveyExpired = isSurveyExpired(currentSurvey);
  const _isSurveyNotPublished = isSurveyNotPublished(currentSurvey);
  const _isSurveyCompleted =
    isSurveyCompleted(currentSurvey.survey) || currentSurvey.completed;
  const isStartDisabled =
    _isSurveyExpired || _isSurveyNotPublished || _isSurveyCompleted;

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const closeDialogPositive = () => {
    closeDialog();
    setResumeWindowShown(false);
  };

  return (
    <>
      {_isSurveyExpired && (
        <AlertBlock type={"error"} hideCloseButton={true}>
          <SurveyTranslation translate={"survey.expired.alert.message"} />
        </AlertBlock>
      )}
      {_isSurveyNotPublished && (
        <AlertBlock type={"error"} hideCloseButton={true}>
          <SurveyTranslation translate={"survey.not.published.alert.message"} />
        </AlertBlock>
      )}
      {_isSurveyCompleted && (
        <AlertBlock type={"error"} hideCloseButton={true}>
          <SurveyTranslation
            translate={msgPrefix + ".already_completed"}
            options={{
              creating_admin_name:
                currentSurvey.survey.creatorUserInfo?.name ??
                currentSurvey.survey.creator,
              creating_admin_email: currentSurvey.survey.creatorUserInfo?.email,
            }}
          />
        </AlertBlock>
      )}
      <Typography className={classes.heading}>
        {currentSurvey.survey.title}
      </Typography>

      {preview && (
        <>
          <AlertBlock hideCloseButton={true} type={"warning"}>
            <SurveyTranslation translate={"survey.preview"} />
          </AlertBlock>
        </>
      )}

      {resumeWindowShown && (
        <>
          <AlertBlock hideCloseButton={true} type={"error"}>
            <SurveyTranslation translate={msgPrefix + ".resume.text"} />
            <div style={{ textAlign: "right" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginTop: "15px", marginBottom: "15px" }}
                onClick={() => resumeSurvey()}
              >
                <SurveyTranslation
                  translate={msgPrefix + ".resume.yes_complete_survey"}
                />
              </Button>
            </div>
          </AlertBlock>

          <div style={{ textAlign: "right" }}>
            <Button
              type="submit"
              variant="contained"
              color="default"
              style={{ marginTop: "15px" }}
              onClick={() => openDialog()}
            >
              <SurveyTranslation
                translate={msgPrefix + ".resume.no_start_all_over"}
              />
            </Button>
          </div>
        </>
      )}

      {!resumeWindowShown && (
        <>
          {currentSurvey &&
            currentSurvey?.survey.availableLanguages.length > 1 && (
              <LanguageSelector
                language={language}
                changeSurveyLanguage={changeSurveyLanguage}
                selectLanguage={currentSurvey.survey.selectLanguage}
                languages={
                  currentSurvey?.survey
                    .availableLanguages as TAvailableSurveyLanguages
                }
              />
            )}
          {currentSurvey.survey.startText && (
            <p
              dangerouslySetInnerHTML={{
                __html: currentSurvey.survey.startText.replace(/\n/g, "<br />"),
              }}
            />
          )}
          <Grid container justifyContent={isXs ? "space-around" : "flex-end" }>
            <Grid item xs={isXs ? 6 : 3}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                style={{ marginBottom: "30px" }}
                disabled={isStartDisabled}
                onClick={() => navigateToIdentificationPage()}
              >
                {currentSurvey.survey.startSurveyButton !== null ? (
                  <>{currentSurvey.survey.startSurveyButton}</>
                ) : (
                  <SurveyTranslation
                    translate={
                      currentSurvey.survey.surveyType ===
                      SurveyInspectionType.SURVEY
                        ? "survey.start.button"
                        : "inspection.start.button"
                    }
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>
          <SurveyTranslation translate={"survey.start_all_over"} />
        </DialogTitle>
        <DialogActions>
          <Button onClick={closeDialog}>
            <SurveyTranslation translate={"generic.no"} />
          </Button>
          <Button onClick={closeDialogPositive} autoFocus>
            <SurveyTranslation translate={"generic.yes"} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withRouter(SurveyStart);
