import * as React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withRouter, RouteComponentProps } from "react-router";
import { FormEvent, useEffect, useState } from "react";
import { reverse } from "named-urls";
import { routes } from "../../../Routes";
import { useDispatch, useSelector } from "react-redux";
import { TDispatch } from "../../../types/Thunk";
import {
  submitSurveyCategoryComment,
  TSurveyCategoryCommentData
} from "../../../actions/SurveyActions";
import { useApp } from "../../../hooks/useApp";
import {
  SurveyCategoryComment as SurveyCategoryCommentAction,
  TSurveysState
} from "../../../types/Survey";
import { IAppState } from "../../../store/Store";
import { getCategoryComment } from "../../../helpers/Survey";
import { compose } from "redux";
import { withSurveyData } from "../../../components/hoc/requiresSurveyData";
import SurveyTranslation from "../../../components/survey/SurveyTranslation";
import { loadStoredRespondentState } from "../../../hooks/useCurrentActiveSurvey";
import { StaticContext } from "react-router";
import { setCurrentPageTitle } from "../../../actions/ContentActions";
import { interpolate } from "../../../Helpers";
import { useMediaQuery, useTheme } from '@material-ui/core';
import AlertBlock from '../../../components/AlertBlock';

type LocationState = {
  nextCategory: string;
  nextSubCategory: string;
  categoryName: string;
};

const SurveyCategoryComment: React.FC<RouteComponentProps<
  any,
  StaticContext,
  LocationState
>> = (props): JSX.Element => {
  const [comment, setComment] = useState<string>("");
  const dispatch = useDispatch<TDispatch>();
  const respondentIdentifier = loadStoredRespondentState();
  const { app } = useApp();
  const errors = app.getError(SurveyCategoryCommentAction);
  const surveysState: TSurveysState = useSelector<IAppState, TSurveysState>(
    state => state.surveysState
  );
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (surveysState.responses) {
      const currentCategoryId =
        props.match.params.subCategoryId || props.match.params.categoryId;
      setComment(
        getCategoryComment(
          surveysState.responses?.categoryComments,
          parseInt(currentCategoryId)
        )
      );
    }
  }, [
    surveysState.responses,
    props.match.params.categoryId,
    props.match.params.subCategoryId
  ]);

  const navigateToSurveyEnd = () => {
    props.history.push(
      reverse(routes.surveySendAnswersPage, {
        id: props.match.params.id
      })
    );
  };

  const navigateToNextStep = () => {
    if (props.location.state.nextCategory) {
      props.history.push(
        reverse(routes.surveyCategory, {
          id: props.match.params.id,
          categoryId: props.location.state.nextCategory,
          subCategoryId: props.location.state.nextSubCategory
        })
      );
    } else {
      navigateToSurveyEnd();
    }
  };

  const submitCommentForm = (e: FormEvent) => {
    e.preventDefault();

    if (!respondentIdentifier) {
      throw new Error(`No stored respondent`);
    }

    const commentData: TSurveyCategoryCommentData = {
      respondentId: respondentIdentifier.id,
      categoryId: props.match.params.categoryId,
      comment
    };
    dispatch(submitSurveyCategoryComment(commentData)).then(() => {
      navigateToNextStep();
    });
  };

  const surveyCategoryCommentOptions = {
    category: props.location.state.categoryName
  };

  const categoryCommentMenu =
    surveysState.currentSurvey?.survey.categoryCommentMenu;
  useEffect(() => {
    if (categoryCommentMenu) {
      dispatch(setCurrentPageTitle(`${categoryCommentMenu}`));
    }
  }, [dispatch, categoryCommentMenu]);

  return (
    <>
      {surveysState.currentSurvey?.preview && (
        <>
          <AlertBlock hideCloseButton={true} type={"warning"}>
            <SurveyTranslation translate={"survey.preview"} />
          </AlertBlock>
        </>
      )}
      <form noValidate autoComplete="off" onSubmit={e => submitCommentForm(e)}>
        <h2 style={{ textAlign: "left" }}>
          {typeof surveysState.currentSurvey?.survey.categoryCommentHeader ===
          "string" ? (
            <>{surveysState.currentSurvey?.survey.categoryCommentHeader}</>
          ) : (
            <SurveyTranslation translate={"survey.category.comment.header"} />
          )}
        </h2>
        <p style={{ textAlign: "left" }}>
          {typeof surveysState.currentSurvey?.survey.categoryCommentBody ===
          "string" ? (
            <>
              {interpolate(
                surveysState.currentSurvey?.survey.categoryCommentBody,
                surveyCategoryCommentOptions
              )}
            </>
          ) : (
            <SurveyTranslation
              translate={"survey.category.comment.intro"}
              options={surveyCategoryCommentOptions}
              escape={false}
            />
          )}
        </p>
        <TextField
          id="outlined-basic"
          label={
            typeof surveysState.currentSurvey?.survey.categoryCommentLabel ===
            "string" ? (
              <>{surveysState.currentSurvey?.survey.categoryCommentLabel}</>
            ) : (
              <SurveyTranslation translate={"survey.category.comment.label"} />
            )
          }
          variant="outlined"
          value={comment}
          onChange={e => setComment(e.target.value)}
          multiline
          rows={3}
          style={{ width: "100%" }}
          error={!!errors?.fields?.comment}
          helperText={errors?.fields?.comment}
        />
        <Grid
          container
          spacing={1}
          style={{ justifyContent: "flex-end", marginTop: "30px" }}
        >
          <Grid item xs={isXs ? 6 : 3}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              {typeof surveysState.currentSurvey?.survey
                .categoryCommentButton === "string" ? (
                <>{surveysState.currentSurvey?.survey.categoryCommentButton}</>
              ) : (
                <SurveyTranslation
                  translate={"survey.submit.category.comment"}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default compose(
  withSurveyData,
  withRouter
)(SurveyCategoryComment) as React.FC;
