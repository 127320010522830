import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useChemicalProductStyles = makeStyles((theme: Theme) =>
  createStyles({
    hazardsList: {
      display: 'flex',
      flexDirection: 'column',
    },
    hazardIcon: {
      backgroundColor: 'transparent',
      width: '60px',
      height: '60px',
      marginRight: '10px',
    },
    companyDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    protectiveMeasurementsContainer: {
      marginTop: '10px',
      marginBottom: '20px',
      paddingBottom: '20px',
      paddingTop: '20px',
      backgroundColor: '#fff3e0',
    },
    protectiveMeasurementsContainerText: {
      paddingTop: '10px',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '80%',
      flexShrink: 0,
    },
    iconList: {
      width: '100%',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  })
);
