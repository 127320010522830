import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import { useChemicalProductStyles } from '../ChemicalProduct.styles';
import { TApiChemicalProductHazard, TApiChemicalProductSignalWord } from '../../../types/Chemical';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { GridList, useMediaQuery, useTheme } from '@material-ui/core';

interface IProtectiveMeasurements {
  exposureControls: string;
  recommendedUse: string;
  ppe: Array<TApiChemicalProductHazard>;
  hazards: Array<TApiChemicalProductHazard>;
  signalWordType?: TApiChemicalProductSignalWord;
}

const ProtectiveMeasurements: React.FC<IProtectiveMeasurements> = ({
  exposureControls,
  recommendedUse,
  ppe,
  hazards,
  signalWordType,
}): JSX.Element => {
  const { t } = useTranslation();
  const classes = useChemicalProductStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.protectiveMeasurementsContainer}>
      <GridList cellHeight={150} cols={isXs ? 2 : 4} className={classes.iconList}>
        {ppe.map((e) => (
          <ListItem style={{display: 'block', paddingRight: '15px'}}>
            <ListItemAvatar>
              <Avatar className={classes.hazardIcon}>
                <img src={e.image} alt={e.label} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={e.label} secondary={e.description} />
          </ListItem>
        ))}
      </GridList>

      {exposureControls && (
        <div
          className={classes.protectiveMeasurementsContainerText}
          dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.exposure_controls')}</b> <p>${exposureControls}</p>` }}
        />
      )}
      {recommendedUse && (
        <>
          <div style={{
            borderStyle: 'dashed',
            borderColor: '#F9C682',
            borderWidth: 1,
            borderRadius: 1,
          }}>
          </div>
          <div
            className={classes.protectiveMeasurementsContainerText}
            dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.recommended_use')}</b> <p>${recommendedUse}</p>` }}
          />
        </>
      )}

      {(hazards && hazards.length > 0) && (
        <>
          <div style={{
            borderStyle: 'dashed',
            borderColor: '#F9C682',
            borderWidth: 1,
            borderRadius: 1,
            marginBottom: '10px',
          }}>
          </div>
          <GridList cellHeight={110} cols={isXs ? 2 : 4} className={classes.iconList}>
            {
              hazards.map((hazard) => {
                return (
                  <ListItem key={hazard.label} style={{display: 'block'}}>
                    <ListItemAvatar>
                      <Avatar className={classes.hazardIcon}>
                        <img src={hazard.image} alt='hazard.label' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={hazard.label} />
                  </ListItem>
                );
              })
            }
          </GridList>
        </>
      )}

      {(signalWordType && signalWordType.name !== 'chemicals.signal_word_none') && (
        <Typography
          className={classes.protectiveMeasurementsContainerText}
          dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.signal_word')}</b> <p style="color: ${signalWordType.color}; display: inline; padding-left: 15px">${t(signalWordType.name)}</p>` }}
        />
      )}
    </div>
  );
};

export default ProtectiveMeasurements;
