import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IPageContentImage } from '../../types/Content';
import { memo, useEffect, useState } from 'react';
import { fetchWithAuthentication } from '../../actions/BaseAction'

interface IImageProps {
  image: IPageContentImage;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      width: '100%',
      padding: '0 0 10px',
      textAlign: 'center',
    },
    image: {
      width: '100%',
      height: 'auto',
    },
  })
);

const Image: React.FC<IImageProps> = memo(({ image }): JSX.Element => {
  const classes = useStyles();
  const [imageSrc, setImageSrc] = useState('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');

  useEffect(() => {
    fetchWithAuthentication(image.url)
      .then(blob => {
        if (blob !== null) {
          setImageSrc(URL.createObjectURL(blob));
        }
      })
      .catch(error => {throw error});
  }, [image.url]);

  return (
    <div className={classes.imageContainer}>
      <img className={classes.image} src={imageSrc} alt={image.name} />
    </div>
  );
});

export default Image;
