import { endReducer } from '../store/Store';
import { SaveInspectionQuestionData, TInspectionActions, TInspectionState } from '../types/Inspection';
import { Reducer } from 'redux';

export const inspectionReducer: Reducer<
  TInspectionState,
  TInspectionActions
> = (state = {}, action) => {
  switch (action.type) {
    case SaveInspectionQuestionData.SET:
      return {
        ...state,
        status: action.payload.status,
      };
    case SaveInspectionQuestionData.SENT:
      return {
        ...state,
        sent: action.payload.sent,
      };
    case SaveInspectionQuestionData.SET_LOCATION:
      return {
        ...state,
        locationId: action.payload.locationId,
      };
    case SaveInspectionQuestionData.SET_QUESTIONS:
      return {
        ...state,
        questionId: action.payload.questionId
      }
    default:
      return endReducer(state, action);
  }
};
