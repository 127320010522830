import { useSelector } from 'react-redux';
import { IAppState } from '../store/Store';
import { TInspectionState } from '../types/Inspection';

export const useInspection = () => {
  const inspectionQuestionState = useSelector<IAppState, TInspectionState>(
    (state: IAppState) => state.inspectionState
  );
  const isInspectionStored = inspectionQuestionState.status === true;
  const isInspectionSent = inspectionQuestionState.sent === true;
  const inspectionLocationId = inspectionQuestionState.locationId ?? 0;
  const inspectionQustionId = inspectionQuestionState.questionId ?? 0;

  return { inspectionLocationId, isInspectionStored, isInspectionSent, inspectionQustionId };
};
