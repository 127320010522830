import { AxiosError, AxiosResponse } from "axios";
import { TApiUserDepartment, TApiUserLocation } from "./User";
import { SurveyInspectionType } from "./Content";

export enum SurveyType {
  ANONYMOUS = "anonymous",
  SEMI_ANONYMOUS = "semi-anonymous",
  REQUIRES_LOGIN = "requires-login",
}

export enum SkipLogicType {
  NONE = 0,
  JUMP_TO_QUESTION = 1,
  JUMP_TO_CATEGORY = 2,
  END_SURVEY = 3,
}

// https://basarat.gitbook.io/typescript/main-1/nominaltyping#using-interfaces
export interface TSurveyRespondentId extends String {
  _respondentIdBrand: string;
}

export interface TApiSurveyEntity {
  id: number;
  name: string;
  surveyType: SurveyInspectionType;
  startTime: Date;
  endTime: Date;
  anonymity: SurveyType;
  showResultToRespondent: boolean;
  allowSavingProgress: boolean;
  limitMultipleSubmissions: boolean;
  comments: boolean;
  disabled: boolean;
  hideCategoryTitle: boolean;
  availableLanguages: TAvailableSurveyLanguages;
  startText?: string;
  endText?: string;
  title: string;
  selectLanguage: string | null;
  startSurveyButton: string | null;
  segmentationHeader: string | null;
  segmentationBody: string | null;
  segmentationId: string | null;
  selectSegmentationOrganisation: string | null;
  selectSegmentationLocation: string | null;
  segmentationContinueButton: string | null;
  questionHeader: string | null;
  questionRequired: string | null;
  questionSkipButton: string | null;
  answerCommentHeader: string | null;
  answerCommentBody: string | null;
  answerCommentLabel: string | null;
  answerCommentSaveButton: string | null;
  categoryCommentMenu: string | null;
  categoryCommentHeader: string | null;
  categoryCommentBody: string | null;
  categoryCommentLabel: string | null;
  categoryCommentButton: string | null;
  categoryContinueButton: string | null;
  surveySubmitHeader: string | null;
  surveySubmitBody: string | null;
  surveySubmitButton: string | null;
  surveyPrintButton: string | null;
  creator?: string;
  creatorUserInfo?: {
    name: string;
    email: string;
  };
}

export type TAvailableSurveyLanguages = Array<{ language: TApiSurveyLanguage }>;

export interface TApiSurveyLanguage {
  languageCode: string;
  name: string;
}

export interface TApiAnswerEntity {
  id: number;
  allowComment: number;
  title: string;
}

export interface TApiQuestionEntity {
  id: number;
  answerGroupId: number;
  mandatory: boolean;
  title: string;
  body: string;
  helpText: string;
  answers: Array<TApiAnswerEntity>;
}

export interface TApiSurveyMessage {
  attribute: string;
  value: string;
  language: {};
}

export interface TSkipLogic {
  questionId: number;
  answerId: number;
  targetId?: number;
  type: SkipLogicType;
}

export interface TApiSurveyCategory {
  id: number;
  catId: number;
  title: string;
  introText: string;
  questions: Array<TApiQuestionEntity>;
  subCategories: Array<TApiSurveyCategory> | null;
  activeQuestions: Array<TApiQuestionEntity | undefined>;
}

export type TApiSurveyItem = {
  survey: TApiSurveyEntity;
  language: string;
  surveyMessages: TApiSurveyMessage;
  completed?: boolean;
  preview?: boolean;
};

export type TApiSurveyResponseAnswer = {
  questionId: number;
  answerId: number;
  answerComment: string;
};

export type TApiSurveyCategoryComment = {
  categoryId: number;
  comment: string;
};

export type TApiSurveyResponsesEntity = {
  answers: Array<TApiSurveyResponseAnswer>;
  categoryComments: Array<TApiSurveyCategoryComment>;
};

export type TApiSurveyIdentificationInfoData = {
  employeeIdentification: boolean;
  departments: Array<TApiUserDepartment>;
  locations: Array<TApiUserLocation>;
};

export type TApiSurveyStatisticsEntity = {
  respondentResult: number;
  companyAverage: number;
  responseCount: number;
  solution: {
    company: string;
  };
};

export type TGetUserDataData = {
  incomplete: object;
  completed: object;
};

export interface TSurveysState {
  readonly data?: Array<TApiSurveyEntity>;
  readonly currentSurvey?: TApiSurveyItem;
  readonly currentSurveyIdentification?: TApiSurveyIdentificationInfoData;
  readonly responses?: TApiSurveyResponsesEntity;
  readonly respondent?: number;
  readonly loading?: boolean;
  readonly surveySequence?: Array<ISurveySequence>;
  readonly questionsList?: any;
  readonly surveyStatistics?: TApiSurveyStatisticsEntity;
  readonly surveyPrint?: string;
  readonly skipLogic?: Array<TSkipLogic>;
}

export interface ISurveySequence {
  data: TApiSurveyCategory;
  questions?: Array<TApiQuestionEntity>;
  subCategories?: Array<ISurveySequence>;
}

export const initialSurveysState: TSurveysState = {
  data: undefined,
  currentSurvey: undefined,
  respondent: undefined,
};

/*
    Survey List
 */
export enum GetSurveyList {
  REQUEST = "GET_SURVEY_LIST_REQUEST",
  SUCCESS = "GET_SURVEY_LIST_SUCCESS",
  FAILED = "GET_SURVEY_LIST_FAILED",
}

export type TGetSurveyListAction =
  | {
      type: GetSurveyList.REQUEST;
    }
  | {
      type: GetSurveyList.SUCCESS;
      payload: AxiosResponse<{ surveys: Array<TApiSurveyEntity> }>;
    }
  | {
      type: GetSurveyList.FAILED;
      payload: AxiosError;
    };

/*
  Survey
 */

export enum GetSurvey {
  REQUEST = "GET_SURVEY_REQUEST",
  SUCCESS = "GET_SURVEY_SUCCESS",
  FAILED = "GET_SURVEY_FAILED",
}

export type TGetSurveyAction =
  | {
      type: GetSurvey.REQUEST;
    }
  | {
      type: GetSurvey.SUCCESS;
      payload: AxiosResponse<TApiSurveyItem>;
    }
  | {
      type: GetSurvey.FAILED;
      payload: AxiosError;
    };

/*
  Start Survey
 */
export enum StartSurvey {
  REQUEST = "START_SURVEY_REQUEST",
  SUCCESS = "START_SURVEY_SUCCESS",
  FAILED = "START_SURVEY_FAILED",
}

export type TStartSurveyAction =
  | {
      type: StartSurvey.REQUEST;
    }
  | {
      type: StartSurvey.SUCCESS;
      payload: AxiosResponse<{ respondent: number }>;
    }
  | {
      type: StartSurvey.FAILED;
      payload: AxiosError;
    };

/*
  Survey structure
 */
export enum GetSurveyStructure {
  REQUEST = "GET_SURVEY_STRUCTURE_REQUEST",
  SUCCESS = "GET_SURVEY_STRUCTURE_SUCCESS",
  FAILED = "GET_SURVEY_STRUCTURE_FAILED",
}

export type TGetSurveyStructureAction =
  | {
      type: GetSurveyStructure.REQUEST;
    }
  | {
      type: GetSurveyStructure.SUCCESS;
      payload: AxiosResponse<{
        categories: Array<TApiSurveyCategory>;
        skipLogic?: Array<TSkipLogic>;
      }>;
    }
  | {
      type: GetSurveyStructure.FAILED;
      payload: AxiosError;
    };

/*
  Survey responses
 */
export enum GetSurveyResponses {
  REQUEST = "GET_SURVEY_RESPONSES_REQUEST",
  SUCCESS = "GET_SURVEY_RESPONSES_SUCCESS",
  FAILED = "GET_SURVEY_RESPONSES_FAILED",
}

export enum ClearSurveyResponses {
  CLEAR = "CLEAR_PREVIOUS_SURVEY_RESPONSES",
}

export type TClearSurveyResponsesAction = {
  type: ClearSurveyResponses.CLEAR;
};

export type TGetSurveyResponsesAction =
  | {
      type: GetSurveyResponses.REQUEST;
    }
  | {
      type: GetSurveyResponses.SUCCESS;
      payload: AxiosResponse<TApiSurveyResponsesEntity>;
    }
  | {
      type: GetSurveyResponses.FAILED;
      payload: AxiosError;
    };

/*
  Survey identification info
 */
export enum GetSurveyIdentificationInfo {
  REQUEST = "GET_SURVEY_IDENTIFICATION_INFO_REQUEST",
  SUCCESS = "GET_SURVEY_IDENTIFICATION_INFO_SUCCESS",
  FAILED = "GET_SURVEY_IDENTIFICATION_INFO_FAILED",
}

export type TGetSurveyIdentificationInfoAction =
  | {
      type: GetSurveyIdentificationInfo.REQUEST;
    }
  | {
      type: GetSurveyIdentificationInfo.SUCCESS;
      payload: AxiosResponse<TApiSurveyIdentificationInfoData>;
    }
  | {
      type: GetSurveyIdentificationInfo.FAILED;
      payload: AxiosError;
    };

/*
  Survey respond
 */
export enum SurveyRespond {
  REQUEST = "SURVEY_RESPOND_REQUEST",
  SUCCESS = "SURVEY_RESPOND_SUCCESS",
  FAILED = "SURVEY_RESPOND_FAILED",
}

export type TSurveyRespondAction =
  | {
      type: SurveyRespond.REQUEST;
    }
  | {
      type: SurveyRespond.SUCCESS;
      payload: AxiosResponse<any>;
    }
  | {
      type: SurveyRespond.FAILED;
      payload: AxiosError;
    };

/*
  Survey category comment
 */
export enum SurveyCategoryComment {
  REQUEST = "SURVEY_CATEGORY_COMMENT_REQUEST",
  SUCCESS = "SURVEY_CATEGORY_COMMENT_SUCCESS",
  FAILED = "SURVEY_CATEGORY_COMMENT_FAILED",
}

export type TSurveyCategoryCommentAction =
  | {
      type: SurveyCategoryComment.REQUEST;
    }
  | {
      type: SurveyCategoryComment.SUCCESS;
      payload: AxiosResponse<TApiSurveyCategoryComment>;
    }
  | {
      type: SurveyCategoryComment.FAILED;
      payload: AxiosError;
    };

/*
  Survey send answers
 */
export enum SurveySendAnswers {
  REQUEST = "SURVEY_SEND_ANSWERS_REQUEST",
  SUCCESS = "SURVEY_SEND_ANSWERS_SUCCESS",
  FAILED = "SURVEY_SEND_ANSWERS_FAILED",
}

export type TSurveySendAnswersAction =
  | {
      type: SurveySendAnswers.REQUEST;
    }
  | {
      type: SurveySendAnswers.SUCCESS;
      payload: AxiosResponse<any>;
    }
  | {
      type: SurveySendAnswers.FAILED;
      payload: AxiosError;
    };

/*
  Survey statistics
 */
export enum GetSurveyStatistics {
  REQUEST = "GET_SURVEY_STATISTICS_REQUEST",
  SUCCESS = "GET_SURVEY_STATISTICS_SUCCESS",
  FAILED = "GET_SURVEY_STATISTICS_FAILED",
}

export type TGetSurveyStatisticsAction =
  | {
      type: GetSurveyStatistics.REQUEST;
    }
  | {
      type: GetSurveyStatistics.SUCCESS;
      payload: AxiosResponse<TApiSurveyStatisticsEntity>;
    }
  | {
      type: GetSurveyStatistics.FAILED;
      payload: AxiosError;
    };

/*
  Survey print
 */
export enum GetSurveyPrint {
  REQUEST = "GET_SURVEY_PRINT_REQUEST",
  SUCCESS = "GET_SURVEY_PRINT_SUCCESS",
  FAILED = "GET_SURVEY_PRINT_FAILED",
}

export type TGetSurveyPrintAction =
  | {
      type: GetSurveyPrint.REQUEST;
    }
  | {
      type: GetSurveyPrint.SUCCESS;
      payload: AxiosResponse<any>;
    }
  | {
      type: GetSurveyPrint.FAILED;
      payload: AxiosError;
    };

/*
  Admin Survey print
 */
export enum GetAdminSurveyPrint {
  REQUEST = "GET_ADMIN_SURVEY_PRINT_REQUEST",
  SUCCESS = "GET_ADMIN__SURVEY_PRINT_SUCCESS",
  FAILED = "GET_ADMIN__SURVEY_PRINT_FAILED",
}

export type TGetAdminSurveyPrintAction =
  | {
      type: GetAdminSurveyPrint.REQUEST;
    }
  | {
      type: GetAdminSurveyPrint.SUCCESS;
      payload: AxiosResponse<any>;
    }
  | {
      type: GetAdminSurveyPrint.FAILED;
      payload: AxiosError;
    };

/*

 */
export enum GetUserData {
  REQUEST = "GET_USER_DATA_REQUEST",
  SUCCESS = "GET_USER_DATA_SUCCESS",
  FAILED = "GET_USER_DATA_FAILED",
}

export type TGetUserData =
  | {
      type: GetUserData.REQUEST;
    }
  | {
      type: GetUserData.SUCCESS;
      payload: AxiosResponse<TGetUserDataData>;
    }
  | {
      type: GetUserData.FAILED;
      payload: AxiosError;
    };

export enum GetAnsweredQuestions {
  REQUEST = "GET_ANSWERED_QUESTIONS_REQUEST",
  SUCCESS = "GET_ANSWERED_QUESTIONS_SUCCESS",
  FAILED = "GET_ANSWERED_QUESTIONS_FAILED",
}

export type TSurveyActions =
  | TGetSurveyListAction
  | TGetSurveyAction
  | TGetSurveyStructureAction
  | TStartSurveyAction
  | TGetSurveyResponsesAction
  | TClearSurveyResponsesAction
  | TGetSurveyIdentificationInfoAction
  | TSurveyRespondAction
  | TSurveyCategoryCommentAction
  | TSurveySendAnswersAction
  | TGetSurveyStatisticsAction
  | TGetSurveyPrintAction
  | TGetAdminSurveyPrintAction
  | TGetUserData;
