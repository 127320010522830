import axios, { AxiosResponse, Method } from 'axios';
import { Dispatch } from 'redux';
import { TDispatch } from '../types/Thunk';
import { SetAppError } from '../types/Error';
import { setupCache } from 'axios-cache-interceptor';

const tenantDomain = window.location.hostname.split('.')[0];
export const baseApiUrl = process.env.REACT_APP_BASE_API_URL_PATTERN?.replace(
  '{tenant}',
  tenantDomain
);

const instance = axios.create({
  baseURL: baseApiUrl,
  headers: {
    'Content-Type': 'application/json;text/html',
    Accept: 'application/json;text/html',
  },
});

export const apiClient = setupCache(instance, {
  ttl: 1000 * 60 * 5,
  methods: ['get'],
  cachePredicate: {
    statusCheck: (status) => status === 200,
  },
  interpretHeader: false,
});

export const apiCall = <
  TAction = any,
  TRequestData = any,
  TResponse extends {} = {}
>(
  action: any,
  method: Method,
  url: string,
  authorized: boolean = false,
  data: TRequestData | null = {} as TRequestData,
  params: any = {}
) => {
  return async (
    dispatch: TDispatch<TAction>
  ): Promise<AxiosResponse<TResponse>> => {
    dispatch({
      type: action.REQUEST,
    });
    try {
      return await apiClient
        .request<TResponse>({
          data,
          headers: {
            Authorization: authorized
              ? 'Bearer ' + localStorage.getItem('token')
              : '',
          },
          method,
          params,
          url,
          cache: {
            ttl: 1000 * 60 * 5,
          },
        })
        .then((res: AxiosResponse<TResponse>) => {
          dispatch({
            type: action.SUCCESS,
            payload: res,
          });

          return res;
        });
    } catch (err) {
      dispatch({
        type: action.FAILED,
        payload: err,
      });

      throw err;
    }
  };
};

interface IErrorPayload {
  message: string;
  code: number;
}

export const errorAction = (payload: IErrorPayload) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SetAppError.SUCCESS,
      payload,
    });
  };
};

export const fetchWithAuthentication = async (url: string) => {
  const token = localStorage.getItem("token");

  try {
    const response = await apiClient.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob'
    });

    if (response.status !== 200 || !response.data) {
      return null;
    }

    return await response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
}
