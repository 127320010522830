import { apiCall } from './BaseAction';
import {
  ClearSurveyResponses,
  GetAdminSurveyPrint,
  GetAnsweredQuestions,
  GetSurvey,
  GetSurveyIdentificationInfo,
  GetSurveyList,
  GetSurveyPrint,
  GetSurveyResponses,
  GetSurveyStatistics,
  GetSurveyStructure,
  StartSurvey,
  SurveyCategoryComment,
  SurveyRespond,
  SurveySendAnswers,
  TGetAdminSurveyPrintAction,
  TGetUserData,
  TGetSurveyAction,
  TGetSurveyIdentificationInfoAction,
  TGetSurveyListAction,
  TGetSurveyPrintAction,
  TGetSurveyResponsesAction,
  TGetSurveyStatisticsAction,
  TGetSurveyStructureAction,
  TStartSurveyAction,
  TSurveyCategoryCommentAction,
  TSurveyRespondAction,
  TSurveyRespondentId,
  TSurveySendAnswersAction,
  GetUserData,
} from '../types/Survey';
import { Dispatch } from 'redux';

export const getSurveyList = () => {
  return apiCall<TGetSurveyListAction, null>(
    GetSurveyList,
    'GET',
    '/surveys',
    true
  );
};

export const getSurvey = (id: number, lang?: string, access?: string | null, preview?: boolean) => {
  return apiCall<TGetSurveyAction, null>(
    GetSurvey,
    'GET',
    `/survey/${id}`,
    true,
    null,
    { lang, access, preview }
  );
};

export const getSurveyStructure = (id: number, lang?: string) => {
  return apiCall<TGetSurveyStructureAction, null>(
    GetSurveyStructure,
    'GET',
    `/survey/${id}/structure`,
    true,
    null,
    { lang }
  );
};

export type TStartSurveyRequestData = {
  surveyId: number;
  languageCode: string;
  respondentName?: string;
  departmentId?: number;
  locationId?: number;
  access?: string | null;
  preview?: boolean;
};
export const startSurvey = (data: TStartSurveyRequestData) => {
  return apiCall<TStartSurveyAction, TStartSurveyRequestData>(
    StartSurvey,
    'POST',
    '/survey/start',
    true,
    data
  );
};

export const getSurveyResponses = (respondentId: TSurveyRespondentId) => {
  return apiCall<TGetSurveyResponsesAction>(
    GetSurveyResponses,
    'GET',
    '/survey/responses',
    true,
    null,
    { respondentId }
  );
};

export const clearPreviousSurveyResponses = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ClearSurveyResponses.CLEAR,
    });
  };
};

export const getSurveyIdentificationInfo = (surveyId: number) => {
  return apiCall<TGetSurveyIdentificationInfoAction>(
    GetSurveyIdentificationInfo,
    'GET',
    `/survey/${surveyId}/identification`,
    true
  );
};

export type TSurveyRespondData = {
  respondentId: TSurveyRespondentId;
  questionId: number;
  answerId: number;
  comment: string;
};

export const surveyRespond = (data: TSurveyRespondData) => {
  return apiCall<TSurveyRespondAction, TSurveyRespondData>(
    SurveyRespond,
    'PUT',
    '/survey/respond',
    true,
    { ...data }
  );
};

export type TSurveyCategoryCommentData = {
  respondentId: TSurveyRespondentId;
  categoryId: number;
  comment: string;
};
export const submitSurveyCategoryComment = (
  data: TSurveyCategoryCommentData
) => {
  return apiCall<TSurveyCategoryCommentAction, TSurveyCategoryCommentData>(
    SurveyCategoryComment,
    'PUT',
    '/survey/categoryComment',
    true,
    { ...data }
  );
};

export type TSendSurveyAnswersData = {
  respondentId: TSurveyRespondentId;
};

export const sendSurveyAnswers = (respondentId: TSurveyRespondentId) => {
  return apiCall<TSurveySendAnswersAction, TSendSurveyAnswersData>(
    SurveySendAnswers,
    'PUT',
    '/survey/complete',
    true,
    { respondentId }
  );
};

export type TGetSurveyStatisticsData = {
  respondentId: TSurveyRespondentId;
};

export const getSurveyStatistics = (respondentId: TSurveyRespondentId) => {
  return apiCall<TGetSurveyStatisticsAction, TGetSurveyStatisticsData>(
    GetSurveyStatistics,
    'GET',
    '/survey/statistics',
    true,
    null,
    { respondentId }
  );
};

export type TGetSurveyPrintData = {
  respondentId: TSurveyRespondentId;
};

export const getSurveyPrint = (respondentId: TSurveyRespondentId) => {
  return apiCall<TGetSurveyPrintAction, TGetSurveyPrintData>(
    GetSurveyPrint,
    'GET',
    '/survey/print',
    true,
    null,
    {
      respondentId,
    }
  );
};

export const getAdminSurveyPrint = (surveyId: number, lang: string | null) => {
  return apiCall<TGetAdminSurveyPrintAction>(
    GetAdminSurveyPrint,
    'GET',
    '/survey/print',
    true,
    null,
    {
      surveyId,
      lang,
    }
  );
};

export const getUserData = (incomplete: object, completed: object, access?: string | null) => {
  return apiCall<TGetUserData, object>(
    GetUserData,
    'POST',
    '/survey/update_user_data',
    true,
    {
      incomplete,
      completed,
      access,
    }
  );
};

export const getAnsweredData = (
  surveyId: number,
  respondentId: TSurveyRespondentId
) => {
  return apiCall<TGetUserData, object>(
    GetAnsweredQuestions,
    'POST',
    `/survey/${surveyId}/answeredData`,
    true,
    { respondentId }
  );
};
