import { createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export const useProcessStyles = makeStyles((theme: Theme) =>
  createStyles({
    hazardsList: {
      display: 'flex',
      flexDirection: 'column',
    },
    hazardIcon: {
      backgroundColor: 'transparent',
      width: '60px',
      height: '60px',
      marginRight: '10px',
    },
    protectiveMeasuresContainer: {
      marginTop: '10px',
      marginBottom: '20px',
    },
    protectiveMeasuresContainerText: {
      paddingTop: '10px',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '80%',
      flexShrink: 0,
    },
  })
);