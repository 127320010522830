import * as React from "react";
import { compose } from "redux";
import { withRouter } from "react-router";
import { withSurveyData } from "../../../components/hoc/requiresSurveyData";
import { useEffect, useState } from "react";
import {
  ISurveySequence,
  TApiQuestionEntity,
  TSurveysState,
} from "../../../types/Survey";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/Store";
import CategoryDescription from "../../../components/survey/CategoryDescription";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Loader from "../../../components/Loader";
import { RouteComponentProps } from "react-router";
import { routes } from "../../../Routes";
import { reverse } from "named-urls";
import { getCategory, getQuestion } from "../../../helpers/Survey";
import { setCurrentPageTitle } from "../../../actions/ContentActions";
import { findIndex, isEmpty } from "lodash";
import SurveyTranslation from "../../../components/survey/SurveyTranslation";
import { useMediaQuery, useTheme } from '@material-ui/core';
import AlertBlock from '../../../components/AlertBlock';

const SurveyCategoryPage: React.FC<RouteComponentProps<any>> = (
  props
): JSX.Element => {
  const surveysState: TSurveysState = useSelector<IAppState, TSurveysState>(
    (state) => state.surveysState
  );
  const [currentCategory, setCurrentCategory] = useState<ISurveySequence>();
  const [currentSubCategory, setCurrentSubCategory] =
    useState<ISurveySequence>();
  const [nextQuestion, setNextQuestion] = useState<TApiQuestionEntity>();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (
      surveysState.currentSurvey?.survey.hideCategoryTitle &&
      currentCategory &&
      nextQuestion
    ) {
      props.history.replace({
        pathname: reverse(routes.surveyQuestion, {
          id: props.match.params.id,
          categoryId: currentCategory?.data.id as number,
          questionId: nextQuestion?.id as number,
          subCategoryId: currentSubCategory?.data.id as number,
        }),
      });
    }
    //eslint-disable-next-line
  }, [surveysState.currentSurvey?.survey, currentCategory, nextQuestion]);

  useEffect(() => {
    setCurrentCategory(
      getCategory(
        surveysState.surveySequence,
        parseInt(props.match.params.categoryId)
      )
    );
  }, [surveysState.surveySequence, props.match.params.categoryId]);

  useEffect(() => {
    if (currentCategory) {
      dispatch(setCurrentPageTitle(currentCategory.data.title));
    }
  }, [currentCategory, dispatch]);

  useEffect(() => {
    if (currentCategory?.subCategories) {
      if (
        isEmpty(currentCategory?.questions) ||
        props.match.params.subCategoryId
      ) {
        setCurrentSubCategory(
          getCategory(
            currentCategory.subCategories,
            parseInt(props.match.params.subCategoryId)
          )
        );
      }
    }
  }, [currentCategory, props.match.params.subCategoryId]);

  useEffect(() => {
    if (currentCategory?.questions && !props.match.params.subCategoryId) {
      setNextQuestion(getQuestion(currentCategory.questions, null));
    } else if (
      currentCategory?.subCategories &&
      currentSubCategory?.questions
    ) {
      setNextQuestion(getQuestion(currentSubCategory.questions, null));
    } else {
      setNextQuestion(undefined);
    }
  }, [currentCategory, currentSubCategory, props.match.params.subCategoryId]);

  const shouldHideMainCategoryIntro = (): boolean => {
    if (currentCategory?.subCategories && currentSubCategory) {
      return (
        !isEmpty(currentCategory.questions) ||
        findIndex(currentCategory.subCategories, (sub: ISurveySequence) => {
          return sub.data.id === currentSubCategory.data.id;
        }) > 0
      );
    }

    return false;
  };

  if (surveysState.currentSurvey?.survey.hideCategoryTitle) {
    return <Loader visible={true} />;
  }

  return (
    <>
      {surveysState.currentSurvey?.preview && (
        <>
          <AlertBlock hideCloseButton={true} type={"warning"}>
            <SurveyTranslation translate={"survey.preview"} />
          </AlertBlock>
        </>
      )}

      {currentCategory ? (
        <CategoryDescription
          mainCategoryTitle={currentCategory.data.title}
          mainCategoryDescription={currentCategory.data.introText}
          subCategoryTitle={currentSubCategory?.data.title}
          subCategoryDescription={currentSubCategory?.data.introText}
          hideMainCategory={shouldHideMainCategoryIntro()}
        />
      ) : (
        <Loader visible={true} />
      )}
      <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
        <Grid item xs={isXs ? 6 : 3}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              props.history.push({
                pathname: reverse(routes.surveyQuestion, {
                  id: props.match.params.id,
                  categoryId: currentCategory?.data.id as number,
                  questionId: nextQuestion?.id as number,
                  subCategoryId: currentSubCategory?.data.id as number,
                }),
              })
            }
          >
            {typeof surveysState.currentSurvey?.survey
              .categoryContinueButton === "string" ? (
              <>{surveysState.currentSurvey?.survey.categoryContinueButton}</>
            ) : (
              <SurveyTranslation translate={"survey.start.questions.button"} />
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default compose(
  withSurveyData,
  withRouter
)(SurveyCategoryPage) as React.FC;
