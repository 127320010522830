import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { useChemicalProductStyles } from '../ChemicalProduct.styles';

interface IHazardsProps {
  expanded: boolean;
  hazardStatements?: string;
  hazardClassification?: string;
  precautionaryStatements?: string
  otherHazards?: string,
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const Hazards: React.FC<IHazardsProps> = ({
  expanded,
  hazardStatements,
  hazardClassification,
  precautionaryStatements,
  otherHazards,
  handleChange,
}): JSX.Element => {
  const { t } = useTranslation();
  const classes = useChemicalProductStyles();

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange(`panel3`)}
      key={'panel3'}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel3bh-content`}
        id={`panel3bh-header`}
      >
        <Typography className={classes.heading}>{t('chemicals.hazards')}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.hazardsList}>
        {hazardClassification && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.hazard_classification')}</b> ${hazardClassification}` }} />
        )}
        {hazardStatements && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.hazard_statements')}</b> ${hazardStatements}` }} />
        )}
        {precautionaryStatements && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.precautionary_statements')}</b> ${precautionaryStatements}` }} />
        )}
        {otherHazards && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.other_hazards')}</b> ${otherHazards}` }} />
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Hazards;
