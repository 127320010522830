import * as React from 'react';
import { TApiChemicalProductVendor } from '../../../types/Chemical';
import { ChangeEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { Grid } from '@material-ui/core';
import { useChemicalProductStyles } from '../ChemicalProduct.styles';
import Button from '@material-ui/core/Button';

interface IEmergency {
  emergencyInformation?: string;
  vendor?: TApiChemicalProductVendor;
  expanded: boolean;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '80%',
      flexShrink: 0,
    },
    supplierButton: {
      borderRadius: '15px',
      backgroundColor: '#7bcb00',
      '&:hover': {
        backgroundColor: '#639714',
      },
    }
  })
);

const Emergency: React.FC<IEmergency> = ({
  emergencyInformation,
  vendor,
  expanded,
  handleChange,
}): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesVendor = useChemicalProductStyles();

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange(`panel10`)}
      key={'panel10'}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel10bh-content`}
        id={`panel10bh-header`}
      >
        <Typography className={classes.heading}>{t('chemicals.emergency')}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.list}>
        {emergencyInformation && (
          <Typography
            dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.emergency_information')}</b> ${emergencyInformation}` }}
          />
        )}
        {vendor && (
          <div className={classesVendor.companyDetails}>
            <h3>{t('chemicals.supplier')}</h3>
            <p>
              {vendor.name}
              <br/>
              {vendor.streetAddress}
              <br/>
              {vendor.postalCode}{' '}{vendor.city},
              <br />
              {vendor.country}
              <br />
              {t('auth.email.label')}: <u><a href={`mailto:${vendor.email}`}>{vendor.email}</a></u>
              <br />
              <br />
              <Grid container justifyContent='space-around'>
                <Grid item xs={12}>
                  <Button
                    type='button'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.supplierButton}
                    href={`tel:${vendor.phone}`}
                  >
                    {t('chemicals.phone')}{' '}{vendor.phone}
                  </Button>
                </Grid>
              </Grid>
            </p>
          </div>
        )}

      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Emergency;