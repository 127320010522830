import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface IExposure {
  controlParameters?: string;
  expanded: boolean;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '80%',
      flexShrink: 0,
    },
  })
);

const Exposure: React.FC<IExposure> = ({
    controlParameters,
    expanded,
    handleChange,
}): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange(`panel7`)}
      key={'panel7'}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel7bh-content`}
        id={`panel7bh-header`}
      >
        <Typography className={classes.heading}>{t('chemicals.exposure')}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.list}>
        {controlParameters && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.control_parameters')}</b> ${controlParameters}` }} />
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Exposure;
