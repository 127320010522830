import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import { useProcessStyles } from '../Process.styles';
import { TApiProcessSymbol } from '../../../types/Process';

interface IProtectiveMeasures {
  ppe: Array<TApiProcessSymbol>
}

const ProtectiveMeasures: React.FC<IProtectiveMeasures> = ({
  ppe
}): JSX.Element => {
  const classes = useProcessStyles();

  return (
    <div className={classes.protectiveMeasuresContainer}>
      {ppe.map((e) => (
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.hazardIcon}>
              <img src={e.image} alt={e.label} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={e.label} secondary={e.description} />
        </ListItem>
      ))}
    </div>
  );
}

export default ProtectiveMeasures;