import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import { ProcessFileType, TProcessState } from '../../types/Process';
import { simpleDateFormat } from '../../Helpers';
import Image from '../cms/Image';
import Description from './panels/Description';
import { fetchWithAuthentication } from '../../actions/BaseAction';
import Planning from './panels/Planning';
import Execution from './panels/Execution';
import Chemical from './panels/Chemical';
import UpdateDate from './panels/UpdateDate';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ProtectiveMeasures from './panels/ProtectiveMeasures';

interface IProcessProps {
  process: TProcessState;
  expandedDescription: boolean;
  expandedPlanning: boolean;
  expandedExecution: boolean;
  expandedChemical: boolean;
  expandedRevised: boolean;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const Process: React.FC<IProcessProps> = ({
  process,
  expandedDescription,
  expandedPlanning,
  expandedExecution,
  expandedChemical,
  expandedRevised,
  handleChange,
}): JSX.Element => {
  const [urlCache, setUrlCache] = useState<{[key: string]: string}>({});

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const getUpdateDate = (date: Date) => {
    return simpleDateFormat(date);
  };

  const handleFileClick = (filePath: string) => () => {
    if (urlCache[filePath]) {
      const cachedBlob = urlCache[filePath];

      window.open(cachedBlob, '_blank');

      return;
    }

    fetchWithAuthentication(filePath)
      .then(blob => {
        if (blob !== null) {
          const blobUrl = URL.createObjectURL(blob);

          setUrlCache((prevCache) => ({ ...prevCache, [filePath]: blobUrl }));

          window.open(blobUrl, '_blank');
        }
      })
      .catch(error => {throw  error});
  }

  return (
    <>
      {process && (
        <>
          {process.activeProcess?.data.image && (
            <div style={{ width: isXs ? '100%' : '35%'}}>
              <Image
                image={{
                  url: process.activeProcess?.data.image as string,
                  name: process.activeProcess?.data.name as string,
                }}
              />
            </div>
          )}
        </>
      )}

      {process.activeProcess && (
        <>
        <ProtectiveMeasures
          ppe={process.activeProcess.ppe}
        />
        <ProtectiveMeasures
          ppe={process.activeProcess.hazards}
        />
        <ProtectiveMeasures
          ppe={process.activeProcess.prohibitions}
        />
        </>
      )}

      {process.activeProcess?.data.description && (
        <Description
          description={process.activeProcess.data.description as string}
          expanded={expandedDescription}
          handleChange={handleChange}
          handleFileClick={handleFileClick}
          processFiles={
            Object.values(process.activeProcess.data.processFiles).filter(file => file.type === ProcessFileType.DESCRIPTION)
          }
        />
      )}

      {process.activeProcess?.data.planning && (
        <Planning
          planning={process.activeProcess.data.planning as string}
          expanded={expandedPlanning}
          handleChange={handleChange}
          handleFileClick={handleFileClick}
          processFiles={
            Object.values(process.activeProcess.data.processFiles).filter(file => file.type === ProcessFileType.PLANNING)
          }
        />
      )}

      {process.activeProcess?.data.execution && (
        <Execution
          execution={process.activeProcess.data.execution as string}
          expanded={expandedExecution}
          handleChange={handleChange}
          handleFileClick={handleFileClick}
          processFiles={
            Object.values(process.activeProcess.data.processFiles).filter(file => file.type === ProcessFileType.EXECUTION)
          }
        />
      )}

      {process.activeProcess?.data.chemicals && process.activeProcess?.data.chemicals.length > 0 && (
        <Chemical
          chemicals={process.activeProcess.data.chemicals}
          expanded={expandedChemical}
          handleChange={handleChange}
        />
      )}

      {process.activeProcess?.data.updateDate && (
        <UpdateDate
          updateDate={
            getUpdateDate(
              process.activeProcess.data.updateDate
            ) as string
          }
          expanded={expandedRevised}
          handleChange={handleChange}
        />
      )}
    </>
  );
}

export default Process;