import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { loadStoredRespondentState } from '../../hooks/useCurrentActiveSurvey';

interface ISurveyTranslationProps {
  translate: string;
  options?: any;
  escape?: boolean;
}

const SurveyTranslation: React.FC<ISurveyTranslationProps> = ({
  translate,
  options,
  escape = true,
}): JSX.Element => {
  const { t } = useTranslation();
  const respondentData = loadStoredRespondentState();
  const [lang, setLang] = useState(respondentData?.lang || 'da');
  const { i18n } = useTranslation();

  useEffect(() => {
    if (respondentData) {
      setLang(respondentData.lang);
    }
  }, [respondentData]);

  useEffect(() => {
    if (lang) {
      i18n.loadLanguages(lang as string);
    }
    //eslint-disable-next-line
  }, [respondentData, lang]);

  const defaultEscape = options?.interpolation?.escapeValue ?? escape;

  return <>{t(translate, { ...options, lng: lang, interpolation: { escapeValue: defaultEscape } })}</>;
};

export default SurveyTranslation;
