import * as React from "react";
import { useEffect, useState } from "react";
import { useChemicalListStyles } from "../../../components/chemicals/ChemicalList.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  filterChemicalsList,
  getChemicalsList
} from "../../../actions/ChemicalActions";
import { IAppState } from "../../../store/Store";
import { TChemicalState } from "../../../types/Chemical";
import ChemicalList from "../../../components/chemicals/ChemicalList";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import PageContent from "../../../components/PageContent";
import { ContentPage } from "../../../types/Content";

const ChemicalListPage: React.FC = (): JSX.Element => {
  const classes = useChemicalListStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const chemicalsState: TChemicalState = useSelector<IAppState, TChemicalState>(
    state => state.chemicalState
  );
  const [allLocations, setAllLocations] = useState<Array<string>>();
  const [chosenLocation, setChosenLocation] = useState<string>("-");
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    dispatch(getChemicalsList());
  }, [dispatch]);

  useEffect(() => {
    const list: Array<string> = [];
    chemicalsState.data?.map(chemical =>
      chemical.locations.forEach(location => {
        const title = location.title;
        if (!list.includes(title)) list.push(title);
      })
    );
    list.sort();

    setAllLocations(list);
  }, [chemicalsState]);

  const filterList = React.useMemo(
    () => debounce((search: string, location: string) => {
      dispatch(filterChemicalsList(search, location === "-" ? "" : location));
    }, 100),
    [dispatch]
  );

  return (
    <>
      <PageContent
        pageType={ContentPage.CHEMICALS_PAGE}
        showAsParagraph={true}
      />
      {!chemicalsState.loading && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>{t("chemicals.filter.location")}</InputLabel>
                <Select
                  value={chosenLocation}
                  onChange={e => {
                    filterList(searchValue, e.target.value as string);
                    setChosenLocation(() => e.target.value as string);
                  }}
                  label={t("chemicals.filter.location")}
                >
                  <MenuItem value={"-"} key={"-"}>
                    {t("chemicals.filter.locations.all")}
                  </MenuItem>

                  {allLocations &&
                    allLocations.map(title => (
                      <MenuItem value={title} key={title}>
                        {title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <TextField
                value={searchValue}
                label={t("chemicals.search.label")}
                className={classes.searchField}
                onChange={e => {
                  filterList(e.target.value, chosenLocation);
                  setSearchValue(() => e.target.value as string);
                }}
              />
            </Grid>
          </Grid>
          <div className={classes.root}>
            <ChemicalList chemicals={chemicalsState.filteredList} />
          </div>
        </>
      )}
    </>
  );
};

export default ChemicalListPage;
